export const SYSTEM_TYPES = {
  Message: 0,
  AssignTo: 1,
  MoveToClose: 2,
  MoveToPending: 3,
  MoveToUnAssign: 4,
  WaitingForResolution: 5,
  FirstTimeResponse: 6,
  ChangeTopic: 7,
  MoveToCase: 8,
  MoveToBot: 9,
  MoveToMyCase: 10,
  TicketUpdated: 11,
  Unsend: 12,
  AdminLeaveGroupMention: 13
};

export const SYSTEM_ROLES_NAME = {
  SUPERADMIN: "SUPERADMIN",
  SUPERVISOR: "SUPERVISOR",
  ADMIN: "ADMIN"
};

export const SOCIAL_TYPES = {
  All: 99
};
