import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style.css";
import CoreuiVue from "@coreui/vue";
import BootstrapVue from "bootstrap-vue";
import "vue-datetime/dist/vue-datetime.css";
import VueCookies from "vue-cookies";
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import InfiniteLoading from "vue-infinite-loading";
import dotenv from "dotenv";
import { currentPlatform } from "@/utils/navigation.js";

dotenv.config();
require("moment/locale/th");

import {
  faPowerOff,
  faDiceD6,
  faPlusCircle,
  faMinusCircle,
  faSyncAlt,
  faBars,
  faTimes,
  faFileUpload,
  faFileDownload,
  faPen,
  faPenSquare,
  faEdit,
  faReceipt,
  faTruck,
  faExclamationCircle,
  faUser,
  faImage,
  faLocationArrow,
  faReply,
  faRobot,
  faSearch,
  faCartPlus,
  faClipboardList,
  faFilter,
  faChevronLeft,
  faMapMarkerAlt,
  faCheck,
  faCommentAlt,
  faCheckCircle,
  faExclamation,
  faPlus,
  faUserCircle,
  faUpload,
  faDownload,
  faComments,
  faSignOutAlt,
  faTimesCircle,
  faCommentDots,
  faTrash,
  faChevronCircleUp,
  faChevronCircleDown,
  faStore,
  faChevronDown,
  faChevronUp,
  faSortAmountDown,
  faLink,
  faFont,
  faSearchPlus,
  faCopy,
  faPhoneAlt,
  faClock,
  faQrcode,
  faPhone,
  faHourglassHalf,
  faExclamationTriangle,
  faFileImport
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faLine } from "@fortawesome/free-brands-svg-icons";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import Vuelidate from "vuelidate";
import "./registerServiceWorker";

// Install Plugin
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(CoreuiVue);
Vue.use(VueCookies);
//Vue.$cookies.config("30d", null, null); //Local Config
//Vue.$cookies.config("30d", null, ".dosetech.co"); //Dev Config
//Vue.$cookies.config("30d", null, ".d3-dots.com"); //Prod Config
Vue.use(VueMoment, {
  moment
});
Vue.use(InfiniteLoading, {
  /* options */
});

library.add(
  faPowerOff,
  faDiceD6,
  faPlusCircle,
  faMinusCircle,
  faSyncAlt,
  faBars,
  faTimes,
  faFileUpload,
  faFileDownload,
  faPen,
  faPenSquare,
  faEdit,
  faReceipt,
  faFileDownload,
  faTruck,
  faExclamationCircle,
  faUser,
  faImage,
  faLocationArrow,
  faReply,
  faRobot,
  faSearch,
  faCartPlus,
  faClipboardList,
  faFilter,
  faChevronLeft,
  faMapMarkerAlt,
  faCheck,
  faCommentAlt,
  faCheckCircle,
  faExclamation,
  faPlus,
  faUserCircle,
  faUpload,
  faDownload,
  faComments,
  faSignOutAlt,
  faTimesCircle,
  faCommentDots,
  faTrash,
  faChevronCircleUp,
  faChevronCircleDown,
  faStore,
  faChevronDown,
  faChevronUp,
  faSortAmountDown,
  faLink,
  faFont,
  faSearchPlus,
  faCopy,
  faPhoneAlt,
  faLine,
  faClock,
  faQrcode,
  faPhone,
  faHourglassHalf,
  faExclamationTriangle,
  faFileImport
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });

Vue.config.productionTip = false;

Vue.prototype.$apiKey = "ChAtA7Ds40p";
Vue.prototype.$axios = axios;
Vue.prototype.$isMobile = currentPlatform();
Vue.prototype.$formatDate = "DD MMM YYYY";
Vue.prototype.$formatDateTime = "DD MMM YYYY HH:mm:ss";
Vue.prototype.$formatTime = "HH:mm";
Vue.prototype.$language = "th";

const MODE = process.env.VUE_APP_MODE ? `${process.env.VUE_APP_MODE}` : "DEV";
if (MODE === "DEV") {
  // Vue.prototype.$baseUrl = "https://uat-customer-api.d-dots.com";
  // Vue.prototype.$baseUrl = "https://demo-tnl-customer.dosetech.co";
  // Vue.prototype.$baseUrl = "https://dev-master-chatbot-customer-api.d-dots.com";
  Vue.prototype.$baseUrl = "https://dev-master-chatbot-admin-api.d-dots.com";
  Vue.prototype.$chatBaseUrl =
    "https://dev-master-chatbot-chatagent-api.d-dots.com/api";
  Vue.prototype.$chatbotUrl =
    "https://dev-master-chatbot-message-api.d-dots.com";
  Vue.prototype.$chatBaseUrlSocket =
    "wss://jti9f59020.execute-api.ap-southeast-1.amazonaws.com/dev";
  Vue.prototype.$backofficeUrl = "https://dev-master-chatbot-admin.d-dots.com/";
  // Vue.prototype.$baseUrlOrder = "https://demo-tnl-customer.dosetech.co";
  // Vue.prototype.$baseUrlOrder =
  //   "https://dev-master-chatbot-customer-api.d-dots.com";
  Vue.prototype.$baseUrlOrder =
    "https://dev-master-chatbot-admin-api.d-dots.com";
  Vue.prototype.$publicVapidKey =
    "BCNGBuzzBin1ZpQwAHuur-sIEneGaGWugF70W3sB3j8f67QrxEG7N6mXy37aBwQ_jFXyxlSW9RfvOPSfT8in6ns";
}

if (MODE === "DEV-YC") {
  Vue.prototype.$baseUrl = "https://dev-master-chatbot-admin-api.d-dots.com";
  Vue.prototype.$chatBaseUrl =
    "https://dev-master-chatbot-chatagent-api.d-dots.com/api";
  Vue.prototype.$chatbotUrl =
    "https://dev-master-chatbot-message-api.d-dots.com";
  Vue.prototype.$chatBaseUrlSocket =
    "wss://jti9f59020.execute-api.ap-southeast-1.amazonaws.com/dev";
  Vue.prototype.$backofficeUrl = "https://dev-master-chatbot-admin.d-dots.com/";
  Vue.prototype.$baseUrlOrder =
    "https://dev-master-chatbot-admin-api.d-dots.com";
  Vue.prototype.$publicVapidKey =
    "BCNGBuzzBin1ZpQwAHuur-sIEneGaGWugF70W3sB3j8f67QrxEG7N6mXy37aBwQ_jFXyxlSW9RfvOPSfT8in6ns";

  // Vue.prototype.$baseUrl = "https://dev-yc-master-chatbot-admin-api.d-dots.com";
  // Vue.prototype.$chatBaseUrl =
  //   "https://dev-yc-master-chatbot-chatagent-api.d-dots.com/api";
  // Vue.prototype.$chatbotUrl =
  //   "https://dev-yc-master-chatbot-message-api.d-dots.com";
  // Vue.prototype.$chatBaseUrlSocket =
  //   "wss://jti9f59020.execute-api.ap-southeast-1.amazonaws.com/dev-yc";
  // Vue.prototype.$backofficeUrl =
  //   "https://dev-yc-master-chatbot-admin.d-dots.com/";
  // Vue.prototype.$baseUrlOrder =
  //   "https://dev-yc-master-chatbot-admin-api.d-dots.com";
  // Vue.prototype.$publicVapidKey =
  //   "BDWxdL6D0EXXDVbKmRboKgI3ZaFqARJJT7txi2X1aI2BTgN7ncNafZ1ixL8GZlfwV27_UDlJjdTcpnT5zAMQE1M";
}

if (MODE === "PROD-YC") {
  Vue.prototype.$baseUrl = "https://ysc-master-chatbot-admin-api.d-chat.co";
  Vue.prototype.$chatBaseUrl =
    "https://ysc-master-chatbot-chatagent-api.d-chat.co/api";
  Vue.prototype.$chatbotUrl =
    "https://ysc-master-chatbot-message-api.d-chat.co";
  Vue.prototype.$chatBaseUrlSocket =
    "wss://jti9f59020.execute-api.ap-southeast-1.amazonaws.com/prod-ysc";
  Vue.prototype.$backofficeUrl = "https://ysc-master-chatbot-admin.d-chat.co/";
  Vue.prototype.$baseUrlOrder =
    "https://ysc-master-chatbot-admin-api.d-chat.co";
  Vue.prototype.$publicVapidKey =
    "BDWxdL6D0EXXDVbKmRboKgI3ZaFqARJJT7txi2X1aI2BTgN7ncNafZ1ixL8GZlfwV27_UDlJjdTcpnT5zAMQE1M";
}

if (MODE === "UAT") {
  Vue.prototype.$baseUrl = "https://uat-master-chatbot-customer-api.d-dots.com";
  Vue.prototype.$chatBaseUrl =
    "https://uat-master-chatbot-chatagent-api.d-dots.com/api";
  Vue.prototype.$chatbotUrl =
    "https://uat-master-chatbot-message-api.d-dots.com";
  Vue.prototype.$chatBaseUrlSocket =
    "wss://jti9f59020.execute-api.ap-southeast-1.amazonaws.com/uat";
  Vue.prototype.$backofficeUrl = "https://uat-master-chatbot-admin.d-dots.com/";
  Vue.prototype.$baseUrlOrder =
    "https://uat-master-chatbot-customer-api.d-dots.com";
  Vue.prototype.$publicVapidKey =
    "BCxHjHS6bXakB0nuIHVSJzrbkyg471wmyIiwNNZcJ3EvgEKvrcgQKFnYNJLdL6BwyCyyinTl1A4xVYR9LZlOUGw";
}

if (MODE === "AOMPLEARN") {
  Vue.prototype.$baseUrl =
    "https://aomplearn-master-chatbot-customer-api.d-dots.com";
  Vue.prototype.$chatBaseUrl =
    "https://aomplearn-master-chatbot-chatagent-api.d-dots.com/api";
  Vue.prototype.$chatbotUrl =
    "https://aomplearn-master-chatbot-message-api.d-dots.com";
  Vue.prototype.$chatBaseUrlSocket =
    "wss://jti9f59020.execute-api.ap-southeast-1.amazonaws.com/aomplearn";
  Vue.prototype.$backofficeUrl =
    "https://aomplearn-master-chatbot-admin.d-dots.com/";
  Vue.prototype.$baseUrlOrder =
    "https://aomplearn-master-chatbot-customer-api.d-dots.com";
  Vue.prototype.$publicVapidKey =
    "BCxHjHS6bXakB0nuIHVSJzrbkyg471wmyIiwNNZcJ3EvgEKvrcgQKFnYNJLdL6BwyCyyinTl1A4xVYR9LZlOUGw";
}

if (MODE === "CLARINS") {
  Vue.prototype.$baseUrl =
    "https://dev-clarins-master-chatbot-admin-api.d-dots.com";
  Vue.prototype.$chatBaseUrl =
    "https://dev-clarins-master-chatbot-chatagent-api.d-dots.com/api";
  Vue.prototype.$chatbotUrl =
    "https://dev-clarins-master-chatbot-message-api.d-dots.com";
  Vue.prototype.$chatBaseUrlSocket =
    "wss://jti9f59020.execute-api.ap-southeast-1.amazonaws.com/dev-clarins";
  Vue.prototype.$backofficeUrl =
    "https://dev-clarins-master-chatbot-admin.d-dots.com/";
  Vue.prototype.$baseUrlOrder =
    "https://dev-clarins-master-chatbot-admin-api.d-dots.com";
  Vue.prototype.$publicVapidKey =
    "BDWxdL6D0EXXDVbKmRboKgI3ZaFqARJJT7txi2X1aI2BTgN7ncNafZ1ixL8GZlfwV27_UDlJjdTcpnT5zAMQE1M";
}

Vue.prototype.$headersChat = {
  "API-KEY": "lDd0T2cHa7s9sT3M",
  "Content-Type": "application/json",
  culture: Vue.prototype.$language,
  Authorization: VueCookies.isKey("d-order-session-token")
    ? `Bearer ${VueCookies.get("d-order-session-token")}`
    : "null"
};

// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    const timestamp = Vue.$cookies.get("d-order-timestamp");
    const d_order_session_token = Vue.$cookies.get("d-order-session-token");
    if (d_order_session_token) {
      if (timestamp && store.state.timestamp) {
        if (timestamp > store.state.timestamp) {
          window.location.href = "/";
        }
      } else if (timestamp && !store.state.timestamp) {
        store.dispatch("setTimestamp", timestamp);
      }
    }
    const token = Vue.$cookies.isKey("d-order-session-token")
      ? Vue.$cookies.get("d-order-session-token")
      : "null";
    config.headers = {
      "API-KEY": "ChAtA7Ds40p",
      Authorization: "Bearer " + token
    };
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response.status == 401) {
      router.push({ path: "/login" });
      // window.location.href = Vue.prototype.$backofficeUrl + "/login";
    } else if (error.response.status == 403) {
      router.push({ path: "/AccessDenied" });
      // window.location.href = Vue.prototype.$backofficeUrl + "/login";
    }
    return Promise.reject(error);
  }
);

//Add route permisssion
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!VueCookies.get("d-order-session-token")) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
