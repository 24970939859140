// /* eslint-disable no-console */

console.log(
  "This console.log() message is from the registerServiceWorker.js file."
);

// // import { register } from "register-service-worker";

// // // if (process.env.NODE_ENV === "production") {
// // // register(`${process.env.BASE_URL}service-worker.js`, {
// // register(`./service-worker.js`, {
// //   ready() {
// //     console.log(
// //       "App is being served from cache by a service worker.\n" +
// //         "For more details, visit https://goo.gl/AFskqB"
// //     );
// //   },
// //   registered() {
// //     console.log("Service worker has been registered.");
// //   },
// //   cached() {
// //     console.log("Content has been cached for offline use.");
// //   },
// //   updatefound() {
// //     console.log("New content is downloading.");
// //   },
// //   updated() {
// //     console.log("New content is available; please refresh.");
// //   },
// //   offline() {
// //     console.log(
// //       "No internet connection found. App is running in offline mode."
// //     );
// //   },
// //   error(error) {
// //     console.error("Error during service worker registration:", error);
// //   }
// // });
// // // }

// const publicVapidKey =
//   "BDWxdL6D0EXXDVbKmRboKgI3ZaFqARJJT7txi2X1aI2BTgN7ncNafZ1ixL8GZlfwV27_UDlJjdTcpnT5zAMQE1M";

// // Check for service worker
// if ("serviceWorker" in navigator) {
//   send().catch(err => console.error(err));
// }

// // Register SW, Register Push, Send Push
// async function send() {
//   // Register Service Worker
//   console.log("Registering service worker...");
//   // const register = await swRegister("./service-worker.js", {
//   const register = await navigator.serviceWorker.register(
//     "./service-worker.js",
//     {
//       scope: "/"
//     }
//   );
//   console.log("Service Worker Registered...");

//   // Register Push
//   console.log("Registering Push...");
//   const subscription = await register.pushManager.subscribe({
//     userVisibleOnly: true,
//     applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
//   });
//   console.log("Push Registered...");

//   // Send Push Notification
//   console.log("Sending Push...");
//   console.log(JSON.stringify(subscription));
//   await fetch("https://5e51-223-24-166-99.ngrok-free.app/subscribe", {
//     method: "POST",
//     body: JSON.stringify(subscription),
//     headers: {
//       "content-type": "application/json"
//     }
//   });
//   console.log("Push Sent...");
// }

// function urlBase64ToUint8Array(base64String) {
//   const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
//   const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

//   const rawData = window.atob(base64);
//   const outputArray = new Uint8Array(rawData.length);

//   for (let i = 0; i < rawData.length; ++i) {
//     outputArray[i] = rawData.charCodeAt(i);
//   }
//   return outputArray;
// }
